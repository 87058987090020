import { UIV_Activity } from 'csl/client/activity/activity-register.js'

// available views within this module
const UIV_Member  = {
  Centre: 'MemberCentre',
  // mainviews
  View: 'MemberView',
  Messages: 'MemberMessages',
  Subs: 'MemberSubs',
  // subviews
  Activity: 'Member_Activity',
  Admin: 'Member_Admin', 
  Details: 'Member_Details',
  Financial: 'Member_Financial',
  Po10: 'MemberPo10',
}

/**
 * MASTER DEFAULT VIEWINFO DATA FOR THIS MODULE
 */
const viewinfo = {
  [UIV_Member.Centre    ]: { icon: 'icm-member-centre', caption: 'Member Centre'    },
  [UIV_Member.View      ]: { icon: 'icm-profile', isworkarea: false },
  [UIV_Member.Activity  ]: { icon: 'icm-activity', title: 'Activity Timeline'  },
  [UIV_Member.Admin     ]: { icon: 'icm-admin', caption: 'Extended', },
  [UIV_Member.Details   ]: { icon: 'icm-detail' },
  [UIV_Member.Financial ]: { icon: 'icm-payment icm-fg-normal', caption: 'Subs' },
  [UIV_Member.Messages  ]: { icon: 'icm-message icm-fg-normal' },
  [UIV_Member.Subs      ]: { icon: 'icm-subscription icm-fg-normal' },
  [UIV_Member.Po10      ]: { icon: 'icm-po10 icm-fg-normal' },
}

// constructors follow separately for easier readability
viewinfo[UIV_Member.Centre  ].loadctor = () => import( './centre/MemberCentre_View.html' )
viewinfo[UIV_Member.View    ].loadctor = () => import( './view/Member_View.html'  )
viewinfo[UIV_Member.Admin   ].loadctor = () => import( './admin/MemberAdmin_View.html' )
viewinfo[UIV_Member.Details ].loadctor = () => import( './detail/MemberDetail_View.html'), 
viewinfo[UIV_Member.Financial].loadctor= () => import( './financial/MemberFinancial_View.html' )
viewinfo[UIV_Member.Messages].loadctor = () => import( './messages/view/MemberMessages_View.html' )
viewinfo[UIV_Member.Subs    ].loadctor = () => import( './subs/MemberSubs_View.html' )
viewinfo[UIV_Member.Po10    ].loadctor = () => import( './po10/MemberPo10_View.html' )

// reused ctors, just specify viewinfo key from other modules
viewinfo[UIV_Member.Activity].ctor = UIV_Activity.Timeline

/**
 * MASTER DEFAULT TABINFO DATA FOR THIS MODULE
 */
const UIV_MemberTab  = {
  Details: 'Details', 
  Financial: 'Subs',
  Activity: 'Activity', 
}

const tabinfo = {
  [UIV_MemberTab.Details ]: { subviews: [ UIV_Member.Details /*, UIV_Member.Admin */ ] },
  [UIV_MemberTab.Financial ]: { subviews: [ UIV_Member.Financial ] },
  [UIV_MemberTab.Activity]: { subviews: [ UIV_Member.Activity ] },
}

function registerMemberViews(uim, UIV) { 
  uim.registerViews(viewinfo)
  uim.registerTabs(UIV_Member, tabinfo)
}


export {
  UIV_Member, 
  UIV_MemberTab, 
  registerMemberViews,
  viewinfo,
  tabinfo,
}
