// available views within this module
const UIV_Club  = {
  Centre: 'ClubCentre',
  Mag: 'ClubMag',
  Records: 'ClubRecords',
  Resources: 'ClubResources',
  Shop: 'ClubShop',
  Training: 'ClubTraining',
  Calendar: 'ClubCalendar',
  Highlights: 'ClubHighlights',
}


/**
 * MASTER DEFAULT VIEWINFO DATA FOR THIS MODULE
 */
const viewinfo = {
  [UIV_Club.Centre    ]: { icon: 'icm-club-centre', caption: 'Club Centre' },
  [UIV_Club.Calendar  ]: { icon: 'icm-meeting icm-fg-normal' },
  [UIV_Club.Mag ]: { icon: 'icm-magazine icm-fg-normal' },
  [UIV_Club.Records ]: { icon: 'icm-record icm-fg-normal' },
  [UIV_Club.Resources ]: { icon: 'icm-link icm-fg-normal' },
  [UIV_Club.Shop  ]: { icon: 'icm-shop icm-fg-normal' },
  [UIV_Club.Training ]: { icon: 'icm-training icm-fg-normal' },
  [UIV_Club.Highlights ]: { icon: 'icm-highlights icm-fg-normal' },
}
// constructors follow separately for easier readability
viewinfo[UIV_Club.Centre  ].loadctor = () => import( './centre/ClubCentre_View.html' )
viewinfo[UIV_Club.Calendar].loadctor = () => import( './calendar/CalendarList_View.html' )
viewinfo[UIV_Club.Mag].loadctor = () => import( './mag/ClubMag_View.html' )
viewinfo[UIV_Club.Records].loadctor = () => import( './records/ClubRecords_View.html' )
viewinfo[UIV_Club.Resources].loadctor = () => import( './resources/ClubResources_View.html' )
viewinfo[UIV_Club.Shop].loadctor = () => import( './shop/ClubShop_View.html' )
viewinfo[UIV_Club.Training].loadctor = () => import( './training/ClubTraining_View.html' )
viewinfo[UIV_Club.Highlights].loadctor = () => import( './highlights/ClubHighlights_View.html' )

function registerClubViews(uim, UIV) {  
  uim.registerViews(viewinfo)
}

export {
  UIV_Club, 
  registerClubViews,  
  viewinfo, 
}

 

