import { OID } from '_shared/oid.js'
import { SharedUIManager } from '_shared/client/app/uim.js'
import { openInNewTab } from 'csl/client/_js/csl-utils.js'

// registration & components
import { registerViews, UIV } from './register.js'

const NEW_MEMBER = 1

class AppUIManager extends SharedUIManager {

  constructor( cfg, store ){
    super( cfg, store )
    registerViews(this)
    this.showAfterLoginView = UIV.FrontDesk
    this.usingHelpTour = true
  }

  afterStart(){
    if (!this.ismobile){
      // this.setupAppBarMainNav()
    //   let { usersettings } = this.store.get()
    //   if (usersettings.showdashboardatstart){
    //     setTimeout( () => { this.showDashboard()}, 2000)
    //   }
    }
    if (this.store.loadLastSession()){
      this.showFrontDesk()
    }
  }

  /*---------------------------------------------------------------------
  major application entities
  ---------------------------------------------------------------------*/
  getEntities(){

    let result = [
      OID.CONTACT,       
      OID.MEMBER, 
      OID.COMPANYCONTACT,
      OID.COMPANY,       
      // OID.LIST, 
      OID.SYSTEM,         
    ]
    return result
  }

  getMainAddOptions(){
    let akeys = [ 
      OID.CONTACT, 
      OID.MEMBER, 
      OID.COMPANY, 
      OID.COMPANYCONTACT,
    ]
    let result = this.getEntityInfos(akeys)
    result.forEach( e => {
      if (e.caption !== '-') {
        e.caption = 'New ' + e.caption  
        e.icon += ' bgcol '
      }
    }) 
    return result
  }  

  getMainAppBarOptions(){
    let akeys = [ 
      UIV.Member.Centre,  
      UIV.Club.Centre,  
      // UIV.Dashboard,  
      UIV.List.Manager,  
      UIV.Document.Documenter,
      UIV.Reporter,
      UIV.GDPR.Manager, 
      UIV.Company.Explorer, 
      UIV.User.View,
      // UIV.User.Settings,
    ]
    let result = this.getViewInfosForArray(akeys)
    return result
  }

  getMainWorkAreasOptions(){
    let akeys = [
      UIV.Club.Centre,  
      UIV.Dashboard,  
      '-',
      UIV.List.Manager,  
      UIV.Document.Documenter,
      UIV.Reporter,
      '-', 
      // UIV.Company.Explorer, 
      UIV.GDPR.Manager, 
      // UIV.System.Settings,
      UIV.User.View,
      UIV.User.Settings,
    ]
    let result = this.getViewInfosForArray(akeys)
    
    if (this.ismobile){
      // result.unshift( { caption: '-' } )
      let vi = this.getViewInfoForKey(UIV.Member.Centre)
      result.unshift( vi )
    }

    this.store.set({ workareaoptions: result })

    return result
  }    

  showNewEntity(oid, args, event){
    if (event.index === NEW_MEMBER ) {
      oid = OID.CONTACT
      args.h1 = 'New Member'
    }
    super.showNewEntity(oid, args, event)
  }


  // getViewKeyForOID(oid, adding){
  //   let result = super.getViewKeyForOID(oid, adding)
  //   let name 
    
  //   let process = true
  //   switch (oid) {
  //     case OID.CONTACT:  name = 'Contact'; break;
  //     case OID.COMPANY:  name = 'Company'; break;
  //     default: process = false
  //   }

  //   if (process) {
  //     result = name + (adding ? 'New' : 'View')
  //   }
  //   return result
  // }

  gotoCender(){
    let url = 'cender.' + window.location.host 
    openInNewTab( url )
  }

}

export { AppUIManager, UIV } 