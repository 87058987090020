import { WSClientStore } from 'csl/client/_js/csl-ws-client.js'
import { BaseAppStore }  from '_shared/client/app/store.js'
import { OID, UIM, UIV }  from 'csl/client/_js/csl-main.js'
import { AppObject } from '_shared/client/app/models.js'



class AppStore extends BaseAppStore{

  reset(cfg){
    super.reset(cfg)
    this.wssclass = WSClientStore

    this.set({ 
      loggedin: cfg.nologin, 
    })

  }
  
  doBeforeInit(){
    this.set({ 
      usingalerts: true, 
      // we're using speech 
      usingspeech: true,
      speechsupported: true,
      hasapps: true, 
      usingtopbar: true,
      fetchtodos: true, 
      // show_advanced_options: true, 
      plans: [], 
    })

    this.defaultcontact = 'cn'
  }

  async doAfterUserLogin(){ 
    await this.getMembershipPlans()
  }


  async getMembershipPlans(){
    let [err, rs] = await this.http.get( 'members/membershipplans' )

    if (err){
      UIT.showErrorMessage( `Error loading membership plans`)
    }
    else if (rs) {
      this.set({ plans: rs })
    }
  }

}


export {
  AppStore, 
}