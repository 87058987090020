<SharedAppView ref:sharedview appname="AMBER">

  <li slot="addoptions" class="cs-toolbar__item u-z-index-appbar" >
    <span  class="dropdown u-z-index-appbar" onclick>
      <i class="icm-add"></i>
      <CslMenu 
        bind:items="addoptions" 
        showicons={true}  
        itemiconclass='_bgcol' 
        
        on:select="$showNewEntityFromMenu(event)" 
      />
    </span>
  </li>

  <li slot="extras" class="cs-toolbar__item u-z-index-appbar {$ismobile ? 'u-display-none' : ''}"  
    on:click|stopPropagation|preventDefault="$showView('MemberCentre')">
    <i class="icm-member-centre"  title="Member Centre"></i>
  </li>

  <li slot="workareas" class="cs-toolbar__item u-z-index-appbar" >
    <span  class="dropdown u-z-index-appbar" onclick>
      <i class="icm-workareas "  title="Available Workareas"></i>
      <CslMenu 
        bind:items="$workareaoptions" 
        showicons={true} 
        cssclass="cs-menu--icons-darkbg u-z-index-appbar" 
        on:select="$showViewFromMenu(event)" 
      />  
    </span>  
  </li>

</SharedAppView>

<script>
  import { store, UIM, UIV } from 'csl/client/_js/csl-main.js'
  
  export default {
    components: {
      SharedAppView : '_shared/client/app/SharedAppView.html',
      CslMenu: 'csl/client/_cmp/csl-menu.html',
    },
  
    store: () => store, 
    data : () => ({
      addoptions: [], 
      workareaoptions: [],
    }),
  
    oncreate(){
      this.refs.sharedview.on( 'sharedcreated', this.setupAppBarMenus() )
    },
  
    methods: {
      setupAppBarMenus(){
        this.set({  
          addoptions: UIM.getMainAddOptions(), 
          // workareaoptions: UIM.getMainWorkAreasOptions() 
        })
      }
  
    },
  
  }  
  </script>
  