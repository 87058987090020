import { UIV_Contact, viewinfo, UIV_ContactTab, tabinfo } from 'csl/client/contact/contact-register.js'
import { UIV_Member, viewinfo as viewinfo_member } from '../member/member-register.js'


// // testing profile page
//viewinfo[UIV_Company.Profile].loadctor = () => import( '../company/profile/CompanyProfile.html'  )

// add extra view to activity
UIV_Contact.Financial = 'Contact_Financial'
viewinfo[UIV_Contact.Financial] = Object.assign( {}, viewinfo_member[UIV_Member.Financial] )

// add extra subview to activity tab
tabinfo[UIV_ContactTab.Activity].subviews.push( UIV_Contact.Financial )


function registerContactViews(uim, UIV) { 
  uim.registerViews(viewinfo)
  uim.registerTabs(UIV.Contact, tabinfo)
}

export { UIV_Contact, registerContactViews }
