import { OID, AssignSystemObjectsModifier } from '_shared/oid.js'
import { UIZONE } from 'csl/client/_js/csl-ui-utils.js'
import { registerHelpInfos, UIV_Help, } from 'csl/client/help/help-register.js'

// views from standard modules
import { UIV_Activity, registerActivityViews } from 'csl/client/activity/activity-register.js'
import { UIV_Calendar, registerCalendarViews } from 'csl/client/calendar/calendar-register.js'
import { UIV_Cmp, registerAppComponents } from 'csl/client/_shared/cmp-register.js'
import { UIV_Company, registerCompanyViews } from 'csl/client/company/company-register.js'
import { UIV_CompanyContact, registerCompanyContactViews } from 'csl/client/companycontact/companycontact-register.js'
// import { UIV_Contact, registerContactViews } from 'csl/client/contact/contact-register.js'
import { UIV_Document, registerDocumentViews } from 'csl/client/doc/document-register.js'
import { UIV_Email, registerEmailViews } from 'csl/client/email/email-register.js'
import { UIV_GDPR, registerGDPRViews } from 'csl/client/gdpr/gdpr-register.js'
import { UIV_List, registerListViews } from 'csl/client/list/list-register.js'
import { UIV_Message, registerMessageViews } from 'csl/client/message/message-register.js'
import { UIV_Todo, registerTodoViews } from 'csl/client/todo/todo-register.js'
// import { UIV_System, registerSystemViews } from 'csl/client/system/system-register.js'
import { UIV_SysAction, registerSystemActions } from 'csl/client/system/actions-register.js'
import { UIV_User, registerUserViews } from 'csl/client/user/user-register.js'

// app-specific views
import { UIV_Contact, registerContactViews } from './_register/contact-register.js'
import { UIV_Club, registerClubViews } from './club/club-register.js'
import { UIV_Member, registerMemberViews } from './member/member-register.js'


/*---------------------------------------------------------------------
main user interface views
---------------------------------------------------------------------*/
const UIV = {
  AlertMan:     'AlertMan',
  Dashboard:    'Dashboard',
  Finder:       'Finder',
  FinderResults: 'FinderResults',
  FrontDesk:    'FrontDesk',
  Home:         'Home',
  Reporter:     'Reporter',
}

/**
 * MASTER VIEWINFO DATA FOR THIS MODULE
 */
let viewinfo = {
  [UIV.AlertMan    ]: { isworkarea: false, zone: UIZONE.NOTIFY },
  [UIV.Dashboard   ]: { /* issingleton: true */ },
  [UIV.Finder      ]: { isworkarea: false, zone: UIZONE.LEFT },
  [UIV.FinderResults]: { isworkarea: true, issingleton: true },
  [UIV.FrontDesk   ]: { isworkarea: false, zone: UIZONE.LEFT },
  [UIV.Home        ]: { isworkarea: true },   // tcnote isworkarea: true  temp
  [UIV.Reporter    ]: { issingleton: true  },
}

// constructors follow separately for easier readability
viewinfo[UIV.AlertMan    ].loadctor = () => import( 'csl/client/alerts/AlertMan_View.html' )
viewinfo[UIV.Dashboard   ].loadctor = () => import( 'csl/client/dashboard/Dashboard.html' )
viewinfo[UIV.Finder      ].loadctor = () => import( 'csl/client/finder/Finder_View.html' )
viewinfo[UIV.FinderResults].loadctor= () => import( 'csl/client/finder/results/FinderResultsPage_View.html' )
viewinfo[UIV.FrontDesk   ].loadctor = () => import( 'csl/client/frontdesk/FrontDesk_View.html' )
viewinfo[UIV.Reporter    ].loadctor = () => import( 'csl/client/report/Reporter_View.html' )
// viewinfo[UIV.Home        ].loadctor = () => import( './cmp/Home.html' )
viewinfo[UIV.Home       ].loadctor = () => import( 'csl/client/_shared/MainLogo.html'  )

/*-------------------------------------------------------------------------------
add imported view keys to the exported main UIV object so available throughout app
--------------------------------------------------------------------------------*/
UIV.Activity = UIV_Activity
UIV.Club = UIV_Club
UIV.Cmp = UIV_Cmp
UIV.Document = UIV_Document
UIV.Calendar = UIV_Calendar
UIV.Company = UIV_Company
UIV.CompanyContact = UIV_CompanyContact
UIV.Contact = UIV_Contact
UIV.Email = UIV_Email
UIV.GDPR = UIV_GDPR
UIV.Help = UIV_Help
UIV.List = UIV_List
UIV.Member = UIV_Member
UIV.Message = UIV_Message
// UIV.System = UIV_System
UIV.SysAction = UIV_SysAction
UIV.Todo = UIV_Todo
UIV.User = UIV_User

/*---------------------------------------------------------------------------
register *ALL* views within this system, called from UIM constructor
----------------------------------------------------------------------------*/
function registerViews(uim){
  
  registerMainViews(uim)

  registerActivityViews(uim, UIV)
  registerAppComponents(uim, UIV)
  registerCalendarViews(uim, UIV)
  registerClubViews(uim, UIV)
  registerCompanyContactViews(uim, UIV)
  registerCompanyViews(uim, UIV)
  registerContactViews(uim, UIV)
  registerDocumentViews(uim, UIV) 
  registerEmailViews(uim, UIV) 
  registerGDPRViews(uim, UIV) 
  registerListViews(uim, UIV)
  registerMemberViews(uim, UIV)
  registerMessageViews(uim, UIV)
  registerSystemActions(uim, UIV)
  // registerSystemViews(uim, UIV)
  registerTodoViews(uim, UIV)
  registerUserViews(uim, UIV)

  // help tours
  registerHelpInfos(uim, UIV)
} 

function registerMainViews(uim) {
  // AssignSystemObjectsModifier( new SystemObjectsModifierClass())

  // viewinfo[UIV.Finder].zone = uim.ismobile ? UIZONE.LEFT :  UIZONE.RIGHT
  uim.registerViews(viewinfo)  
}

// class SystemObjectsModifierClass {

//   getName( result, oid,tolower, nospaces, wantplural){
//     let process = true
//     switch (oid) {
//       case OID.CONTACT:  result = 'Member'; break;
//       case OID.COMPANY:  result = 'Institution'; break;
//       default: process = false
//     }

//     if (process) {
//       if (wantplural) { result += 's'  }
//       if (tolower) result = result.toLowerCase()
//       if (nospaces) result = result.replace(' ', '')
//     }
//     return result
//   }

// }


export { registerViews, UIV }
