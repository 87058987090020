import App from './App.html'
import { AppStore }  from './store.js'
import { AppUIManager, UIV as UIViewKeys }  from './uim.js'
import { HttpService } from '_shared/client/app/http.js'
import { OID as AppObjects }  from '_shared/oid.js'
import { initMain }  from 'csl/client/_js/csl-main.js'

/*----------------------------------------------------------
and we're off!
----------------------------------------------------------*/
initMain( App, AppStore, AppUIManager, UIViewKeys, HttpService, AppObjects )   